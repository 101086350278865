<!-- <app-header title="Reward details"></app-header> -->
<div class="pageHeader row mx-0 px30">
  <div
    class="
      col-12
      justify-content-center
      d-flex
      position-relative
      header-height
      align-items-center
      d-flex
    "
  >
    <a (click)="onClickRewardDetailsBack()">
      <i class="fa fa-angle-left"></i>
    </a>
    <h1>Reward details</h1>
  </div>
</div>
<section class="reward-details-container min-vh-100" id="scroll">
  <div class="blue-card-bg">
    <p class="mb-0">Congratulations!</p>
    <label>You have received a reward.</label>
    <!-- <div class="white-card mb-4" *ngFor="let voucher of voucherObjectsToMap">
        <div class="dot-box text-center fW600">
          <h4 class="mt10">{{ voucher.code }}</h4>
        </div>
        <div class="mt12">
          <div class="row">
            <span class="col-4 col-gray fs12">Issued date </span
            ><span class="col-2"></span>
            <Span class="col-6 text-right">{{ voucher.issuedDate }}</Span>
          </div>
          <div class="row">
            <span class="col-4 col-gray fs12">Value </span
            ><span class="col-2"></span>
            <Span class="col-6 text-right">PHP 200</Span>
          </div>
          <div class="row">
            <span class="col-6 col-gray fs12">Expiration date</span>
            <Span class="col-6 text-right">{{ voucher.expiryDate }}</Span>
          </div>
        </div>
      </div> -->
    <div class="white-card mt-4">
      <div class="gold-bg-img">
        <img src="../../../../assets/images/gold-bg-card-img.png" />

        <div class="gift-away-icon">
          <img
            src="../../../../assets/images/GALogoColored.png"
            width="50px"
            height="38px"
            class="mt-1"
          />
        </div>
        <div class="gift-content">
          <h6 class="font-darkBlue fW600 mb-0">₱200 Giftaway Voucher</h6>
          <p class="font-blue bg-white fs12">
            <img
              class="px-1"
              src="../../../../assets/images/link-icon.svg"
            /><span class="fW600">{{ rewardId }}</span>
          </p>
        </div>
      </div>
      <!-- <div class="text-dark text-center mt-1 fs12">
        Visit the link above to claim your voucher.
      </div> -->
      <!-- <div class="d-flex justify-content-around buttons mt-2">
          <button
            class="primaryBtn btn view-link"
            (click)="
              onClickViewLinkInNewTab(rewardId)
            "
          >
            View link in new tab
            <img class="mb-1 px-1" src="../../../../assets/images/visit-link.png" />
          </button>
          <button
            class="primaryBtn btn-white copy-link fW900"
            (click)="onClickCopyLink(rewardId)"
          >
            Copy link
          </button>
        </div> -->
<div class="pt16">
  <button
  class="primaryBtn btn-white copy-link fW900"
  (click)="onClickCopyLink(rewardId)"
>
  Copy link
</button>
</div>
       
    </div>
    <div class="mt-4 text-center">
      <p class="font-darkblue fs16 fW900">PARTICIPATING BRANDS</p>
    </div>
    <div class="mt-4 participating-brands">
      <p class="fs12 text-dark text-center fW600">RETAIL</p>
      <div class="row justify-content-center mt-2 fs12">
        <div class="col-4 py-2">
          <img
            src="../../../../assets/images/glucerna/Retail/SM-Gift-Pass.png"
          />
        </div>
        <div class="col-4 my-1 px-2 py-0 fW600">
          <img
            src="../../../../assets/images/glucerna/Retail/Robinsons-Supermarket.png"
          />
        </div>
        <div class="col-4 py-3 fW600">
          <img src="../../../../assets/images/glucerna/Retail/Landmark.png" />
        </div>
      </div>
      <div class="row justify-content-center mt-4 fs12">
        <div class="col-4 py-2 text-right fW600">
          <img src="../../../../assets/images/glucerna/Retail/Puregold.png" />
        </div>
        <div class="col-4 py-2 text-center fW600">
          <img src="../../../../assets/images/glucerna/Retail/Metro-DS.png" />
        </div>
        <div class="col-4 py-2 text-left fW600">
          <img
            src="../../../../assets/images/glucerna/Retail/Mercury-Drug.png"
          />
        </div>
      </div>

      <div class="row justify-content-center mt-4 fs12">
        <div class="col-6 py-2 text-right fW600">
          <img
            src="../../../../assets/images/glucerna/Retail/marketplace.png"
          />
        </div>
        <div class="col-6 py-2 text-left fW600">
          <img src="../../../../assets/images/glucerna/Retail/Shopwise.png" />
        </div>
      </div>

      <div class="row justify-content-center mt-4 fs12">
        <div class="col-6 py-2 text-right fW600">
          <img src="../../../../assets/images/glucerna/Retail/FamilyMart.png" />
        </div>
        <div class="col-6 py-2 text-left fW600">
          <img
            src="../../../../assets/images/glucerna/Retail/AllDay-Supermarket.png"
          />
        </div>
      </div>

      <div class="row justify-content-center mt-4 fs12">
        <div class="col-6 py-2 text-right fW600">
          <img src="../../../../assets/images/glucerna/Retail/Landers.png" />
        </div>
        <div class="col-6 py-2 text-left fW600">
          <img src="../../../../assets/images/glucerna/Retail/Sunmart.png" />
        </div>
      </div>

      <p class="fs12 text-dark text-center fW600 mt-4 fW600">E-COMMERCE</p>

      <div class="row justify-content-center mt-4 fs12">
        <div class="col-6 py-2 text-right fW600">
          <img src="../../../../assets/images/glucerna/ecommerce/Lazada.png" />
        </div>
        <div class="col-6 py-2 text-left fW600">
          <img src="../../../../assets/images/glucerna/ecommerce/Shopee.png" />
        </div>
      </div>

      <p class="fs12 text-dark text-center fW600 mt-4 fW600">
        HEALTH AND LIFESTYLE
      </p>
      <div class="row justify-content-center mt-2">
        <div class="col-6 py-2 text-left fW600">
          <img
            src="../../../../assets/images/glucerna/Health_and_Lifestyle/Hi-Precision Diagnostics.png"
          />
        </div>
        <div class="col-6 py-2 text-right fW600">
          <img
            src="../../../../assets/images/glucerna/Health_and_Lifestyle/MyHealth Clinic.png"
          />
        </div>
      </div>
      <div class="row justify-content-center mt-2">
        <div class="col-6 py-2 text-right fW600">
          <img
            src="../../../../assets/images/glucerna/Health_and_Lifestyle/QualiMed.png"
          />
        </div>
        <div class="col-6 py-2 text-left fW600">
          <img
            src="../../../../assets/images/glucerna/Health_and_Lifestyle/GAOC.png"
          />
        </div>
      </div>

      <div class="row justify-content-center mt-2">
        <div class="col-4 py-2 text-left fW600">
          <img
            src="../../../../assets/images/glucerna/Health_and_Lifestyle/Konsulta MD.png"
          />
        </div>
        <div class="col-4 py-2 text-center fW600">
          <img
            src="../../../../assets/images/glucerna/Health_and_Lifestyle/Sky-Med.png"
          />
        </div>
        <div class="col-4 py-2 text-right fW600">
          <img
            src="../../../../assets/images/glucerna/Health_and_Lifestyle/Fashion Smile Dental Spa.png"
          />
        </div>
      </div>

      <div class="row justify-content-center mt-2">
        <div class="col-6 py-2 text-right fW600">
          <img
            src="../../../../assets/images/glucerna/Health_and_Lifestyle/Ride-Revolution.png"
          />
        </div>
        <div class="col-6 py-2 text-left fW600">
          <img
            src="../../../../assets/images/glucerna/Health_and_Lifestyle/BioBalance.png"
          />
        </div>
      </div>

      <div class="row justify-content-center mt-2">
        <div class="col-6 py-2 text-left fW600">
          <img
            src="../../../../assets/images/glucerna/Health_and_Lifestyle/Spinal-Care-Chiropractic.png"
          />
        </div>
        <div class="col-6 py-2 text-right fW600">
          <img
            src="../../../../assets/images/glucerna/Health_and_Lifestyle/The-Smile-Bar.png"
          />
        </div>
      </div>
    </div>

    <div class="pb100">
      <h4 class="mt40 col-blue text-center fW900">TERMS AND CONDITIONS</h4>
      <!-- <p>Effective Date of these Online Terms of Use: 5/17/2013</p> -->
      <ul class="disc-inside px-0 my-4 pb70 fs12 cl-g lh18 text-left">
        <li>
          The eGift code must be given to the manager (for validation)
          <strong class="fW600">before</strong> using it for purchase at the
          location. Multiple eGift codes may be used per transaction.
        </li>
        <li>
          The eGift may not be valid for use on sale or certain promotional
          items/services.
        </li>
        <li>The eGift cannot be exchanged for cash.</li>
        <li>
          The eGift is for one-time use only. If total value of eGift is not
          used up, there will be no cash change.
        </li>
      </ul>
    </div>
  </div>

  <img
    src="../../../../assets/images/scroll_up.svg"
    class="scroll-img"
    (click)="scrollToTop()"
  />
</section>

<app-menu-pediasure
  [customerNamePresent]="customerNamePresent"
></app-menu-pediasure>
