import { Component, OnInit, ViewChild } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from 'src/app/service/api.service';
import { BugsnagService } from 'src/app/services/bugsnag.service';
import { first } from 'rxjs/operators';
@Component({
  selector: "app-progress-badge-pediasure",
  templateUrl: "./progress-badge-pediasure.component.html",
  styleUrls: ["./progress-badge-pediasure.component.scss"],
})
export class ProgressBadgePediasureComponent implements OnInit {
  statusAppetite: boolean = false;
  statusWTGain: boolean = false;
  statusHTGain: boolean = false;
  statusLSD: boolean = false;
  statusFields = []
  customerData: any;
  customerNamePresent = false;
  @ViewChild("productNotYetScanned", { static: true })
  productNotYetScanned: ModalDirective;
  @ViewChild("progressInfoPopup", { static: true })
  progressInfoPopup: ModalDirective;
  @ViewChild("notYetTimeToClaim", { static: true })
  notYetTimeToClaim: ModalDirective;
  @ViewChild("badgePopup", { static: true })
  badgePopup: ModalDirective;
  appetite: any = '0'
  weightGain: any = '0'
  heightGain: any = '0'
  lessSickdays: any = '0'
  updatedCustomer: any;
  updatedCustomFields = [];
  mobile: string;
  name: any;
  profileImage: any;
  uniqueId: any;
  extension: any;
  visible: boolean;
  finalUserImage: any;

  ngOnInit(): void {
    this.customerNamePresent = true;
    this.getCapCustomer()
  }

  constructor(private router: Router,
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private bsService: BugsnagService) { }


  getCapCustomer() {
    this.mobile = sessionStorage.getItem('mobileNo')
    if (this.mobile) {
      this.spinner.show()
      this.apiService.getCapCust(this.mobile).subscribe((res: any) => {
        if (res['status'] == 'success') {
          this.spinner.hide();
          this.customerData = res['result'];
          this.name = this.customerData?.firstname + this.customerData?.lastname
          this.statusFields = this.customerData.custom_fields['field']
          console.log("statusFields", this.statusFields)
          let image = this.statusFields.filter((res) => res.name == 'profileimage');
          this.profileImage = image[0]?.value;
          console.log(this.profileImage)
          this.uniqueId = this.profileImage?.split('.')[0];
          this.extension = this.profileImage?.split("")[this.profileImage.length - 1]
          this.getImage(this.uniqueId, this.extension)

          this.statusFields.forEach((field: any) => {
            if (field['name'] == "appetite") this.appetite = field['value']
            if (field['name'] == "weight_gain") this.weightGain = field['value']
            if (field['name'] == "height_gain") this.heightGain = field['value']
            if (field['name'] == "lesssickdays") this.lessSickdays = field['value']

          })
        } else {
          this.spinner.hide();
          this.customerNamePresent = false;
        }
      }, err => {
        this.spinner.hide();
        console.log(err);
        this.bsService.notifyError(err);
      })
    } else {
      this.customerNamePresent = false;
    }

  }

  getImage(uniqueId, extension) {
    let getMobile = sessionStorage.getItem('mobileNo');
    let getAuthKey = sessionStorage.getItem('authToken');

    this.spinner.show()
    this.apiService
      .getSavedImage(getMobile, getAuthKey, uniqueId, extension)
      .pipe(first())
      .subscribe((res: any) => {
        this.spinner.hide();
        this.finalUserImage = res.file_path;

        this.visible = false;
      }, err => {
        this.spinner.hide();
        this.bsService.notifyError(err);
      });
  }

  openInfoModal() {
    this.productNotYetScanned.show();
  }
  openEarnModal(){
    this.progressInfoPopup.show();
  }
  closeInfoModal() {
    this.productNotYetScanned.hide();
    this.progressInfoPopup.hide();
  }

  onClickNotScanned() {
    this.productNotYetScanned.show()
  }

  onClickNotYetTimeToClaim(status) {
    switch (status) {
      case 'appetite':
        this.statusAppetite = true
        this.statusWTGain = this.statusHTGain =
          this.statusLSD = false

        break;
      case 'weightGain':
        this.statusWTGain = true
        this.statusAppetite = this.statusHTGain
          = this.statusLSD = false

        break;
      case 'heightGain':
        this.statusHTGain = true
        this.statusAppetite = this.statusWTGain =
          this.statusLSD = false

        break;
      case 'lessSickdays':
        this.statusLSD = true
        this.statusHTGain = this.statusWTGain =
          this.statusAppetite = false
        break;
    }
    console.log("status: ", status)

    if (this.appetite == '1' || this.heightGain == '1' ||
      this.weightGain == '1' ||
      this.lessSickdays == '1')
      this.notYetTimeToClaim.show()

    // this.statusHTGain = this.statusWTGain =
    //   this.statusAppetite = this.statusLSD = false
  }

  openBadgePopup(status: any) {
    switch (status) {
      case 'appetite':
        this.statusAppetite = true
        this.statusWTGain = this.statusHTGain =
          this.statusLSD = false

        break;
      case 'weightGain':
        this.statusWTGain = true
        this.statusAppetite = this.statusHTGain
          = this.statusLSD = false

        break;
      case 'heightGain':
        this.statusHTGain = true
        this.statusAppetite = this.statusWTGain =
          this.statusLSD = false

        break;
      case 'lessSickdays':
        this.statusLSD = true
        this.statusHTGain = this.statusWTGain =
          this.statusAppetite = false
        break;
    }
    if (this.appetite == '2' || this.heightGain == '2' ||
      this.weightGain == '2' ||
      this.lessSickdays == '2')
      this.badgePopup.show()

    // this.statusHTGain = this.statusWTGain =
    //   this.statusAppetite = this.statusLSD = false
  }

  closeModal() {
    this.notYetTimeToClaim.hide()
  }

  closeBadge() {
    this.badgePopup.hide()
  }

  claimBadge(status: any) {
    console.log("status: ", status)
    let badgeName
    switch (status) {
      case 'appetite':
        badgeName = "appetite"
        break;
      case 'weightGain':
        badgeName = "weight_gain"
        break;
      case 'heightGain':
        badgeName = "height_gain"
        break;
      case 'lessSickdays':
        badgeName = "lesssickdays"
        break;
    }

    // call backend api


    let body = {
      mobile: this.mobile,
      badgeName: badgeName
    }
    this.spinner.show()
    this.apiService.updateBadge(body).subscribe((res: any) => {
      console.log(res)
      if (res['status'] == 'success') {
        this.spinner.hide()
        this.updatedCustomer = res.result.response.customers['customer'][0]
        this.updatedCustomFields = this.updatedCustomer.custom_fields['field']
        this.updatedCustomFields.forEach((field: any) => {
          if (field['name'] == "appetite") this.appetite = field['value']
          if (field['name'] == "weight_gain") this.weightGain = field['value']
          if (field['name'] == "height_gain") this.heightGain = field['value']
          if (field['name'] == "lesssickdays") this.lessSickdays = field['value']

        })
        this.router.navigate(['/pediasure/progress-badge-claimed'])


      } else {
        this.spinner.hide()
        console.log("error in updating badge")
      }
    })
  }



}
