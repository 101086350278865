<section class="globalContainer px-4 py-4 bg-lightBlue">
  <div class="disclaimer mt-5 text-center">
    <img src="../../../assets/images/new/logo2.png" alt="Points added" />

    <p class="mb-3 txt">
      <strong class="blue">
        Welcome to Abbott Alaga Rewards! You got
        <span class="light-blue">50 points</span> for signing up.</strong
      >
    </p>
    <p class="txt1">
      To earn your points from your
      <span class="light-blue" *ngIf="category == 'ensure'"
        >Ensure Gold <sup>® </sup></span
      >
      purchase, click <span class="light-blue">Next. </span>
      <span class="light-blue" *ngIf="category == 'glucerna'"
        >Glucerna<sup>® </sup></span
      >
      purchase, click <span class="light-blue">Next. </span>
      <span class="light-blue" *ngIf="category == 'pediasure'"
        >Pediasure Plus<sup>® </sup></span
      >
      <span class="light-blue" *ngIf="category == 'similac'"
      >Similac<sup>®</sup><br>Gain school <sup>®</sup></span
    >
      purchase, click <span class="light-blue">Next. </span>
    </p>
    <p *ngIf="isScanCodeAvailable && invalidQR == 'false'" class="mb-5">
      To earn your points from your
      <strong class="blue">Ensure Gold<sup>®</sup></strong
      ><br />
      purchase, click <strong class="blue">Next.</strong>
    </p>

    <div class="btnSection" *ngIf="isScanCodeAvailable && invalidQR == 'false'">
      <button type="button" class="primaryBtn" (click)="onClickContinue()">
        Next
      </button>
    </div>
    <div class="btnSection" *ngIf="isScanCodeAvailable && invalidQR == 'true'">
      <!-- <button type="button" class="primaryBtn" (click)="onClickScanNow()">
        Scan QR code to earn now!
      </button> -->
      <button
        type="button"
        class="primaryBtn mt-2"
        (click)="onClickDashboard()"
      >
        Proceed to dashboard
      </button>
    </div>
    <div class="btnSection" *ngIf="!isScanCodeAvailable || invalidQR == null">
      <!-- <button type="button" class="primaryBtn" (click)="onClickScanNow()">
        Scan QR code to earn now!
      </button> -->
      <button
        type="button"
        class="primaryBtn mt-2"
        (click)="onClickDashboard()"
      >
        Next
      </button>
    </div>
    <div class="mt40 text-center fs10 font-grey promo-permit">
      Per DOH-FDA CFRR Promo Permit 0009 s. 2022
    </div>
    <div class="rectangle text-center"></div>
  </div>
</section>
