export const environment = {
    production: false,
    // API_URl: "https://abbott-ph-api-dev.spurtreetech.com/api"
    AUTH_BASE_URL: 'https://apac-s3wrapper.capillarytech.com',
    API_BASE_URL: 'https://apac-apigateway.capillarytech.com/mobile/v2/api',
    SALESFORCE_BASE_URL: ' https://abbott-api-uatforrelease.sttarter.com/api/v1',
    CMS_URL:
      'https://api.sttarter.com/contentsystem/4cb2ff6a809b43714315b225e207afbe',
    API_URl_TRACEBILITY: 'https://abbott-api-uatforrelease.sttarter.com/api',
    IMAGE_UPLOAD: 'https://apac-apigateway.capillarytech.com/mobile/v2/',
    EMAIL_CHECK_API: 'https://abbott-api-uatforrelease.sttarter.com/api/v1',
    STTARTER_GAMEPLAY_REWARD:
      'https://api.sttarter.com/contentsystem/4cb2ff6a809b43714315b225e207afbe',
    STTARTER_GAMEPLAY_FIRSTTIME_SCAN:
      'https://api.sttarter.com/contentsystem/4cb2ff6a809b43714315b225e207afbe/firsttimescanuat',
    STTARTER_GAMEPLAY_LUCKY_DRAW:
      'https://api.sttarter.com/contentsystem/4cb2ff6a809b43714315b225e207afbe/luckydrawuat',
    // GAME_MAP_API: "https://cap-intouch-in.sttarter.com",
    GAME_MAP_API: 'https://cap-apac-api-com.sttarter.com/v1.1',
    LICENSEKEY:"AfSSW2TLRgepAolslgu0JM8dn5ONHT255kDCgKNcAefCA87hU0BJIOV3CA4IU/VPoEXj/+ZxufgDEw3kVHBQlTF14WpPXyXzKWkCwJZP68FZMzXHTVr0nbhYPyAqRa1osz8MTzYuC4ToCFhORCTEr4UVt8gxccdk22gp4gBp2D06SpqrekbJ9ZxP7+6IRuHnQ3yGgbd3AZH+O/uLM0eeVyMQ7cMeT5PbHVbl6HZ1/DhaUmRll0pE1Dh2ZFGEefUt4HDL5ikWfxuHWX+TtnMulrEvhcjTCI0pL3eaH3ZXcN7jdVfKW1OkSTUPbj9wZpeuCm8IoANZfZAmbYOzxQFVgS10mpXVAF21CzBhDiNtXsSMEEv6EVwX+3BTB9QXWgP87UT7SSNivUXBJnMwb1leCB5GXreBXAuMHWEWnCkCxHHbZ1u4/E6627RUz4rQJGozBAg+cUw2HmkhTYCpIG3fGo4J1kHJH4RrZVSajHFUrtmiWLn7Y4iRvrVSrj7GLYulzDScglHKi8d9dpMU3LigPhVM6xHDN4JYqSStFOzkRKjK1kaUSiHK4jd4j7melLVyLOaTpcyWC1hCwDt2xgsParACRNR8At+d+FE6xoAs0d4wImnYKVmfLVfv8J6vI1e/anKKZOBYanoF4+u2qwMhxaBrIe7jljYFthe+vD54whMgUZ68gthNlAwBpjsQANBgGkTFtkp72X/1o4LU1liylDbJe83FV0UepNH1SlVVQm6pdwOG8VF5t2qLyafYm81VosQEo5DqzPvDGf+39LRm0daq1YuIOG0xCHH3ke/Jqj6nvRZK0rt/uCc0U8R1GSGM50EFJe8sVa5UorY0oG0Th2108MzzAi7IX/wFSUcZ2CyQA1BI26rKMVZ0jp5mQMlXST8yV0GXP50Rpiqz69HZX3OBNdGai/rwb6JhlZYH7KYMcyjZyi7JC2xexv/RZrOk8kBqQDVvOpwarBij42FZNUMqljF811SJnrNKjpgrv4GUEPPq6a13SbrWZHkDdJEqURdKPpDp93N2QNs21dvvLABx5SEF9kidQLCAvTZhGLRrJLUZEv/uFETR3tKzKMjS71NAD5Ti5yjHRjT+/ZvWippvtXa5UTtu6fHnPD7261RG1YjgfS19zUOC2hp1ZLRSMf5p56/gBEvTNUznnLoudUeKV9w=",
  
  
  
    NUTRITION_CUST_ADD_URL: 'https://cap-intouch-in.sttarter.com',
    NUTRITION_TILL_ID: 'nutrition',
    OAUTH_TOKEN: 'https://cap-apac-api-com.sttarter.com/v3',
    SOCIAL_LOGIN_URL: 'https://cap-s3-apac.sttarter.com',
  
    BRAND: 'ABBOTTPHAARUAT',
    //BRAND: 'ABBOTTPHAARDEMO',
    DEVICE_ID: '1234551231',
    IDENTIFIER_TYPE: 'MOBILE',
    //TILL_ID: 'abbott.sg.demo.admin',
    //TILL_ID: 'abbottphdemo',
    TILL_ID: 'abbott.ph.demo.1',
    COUNTRY_CODE: 'PH-AAR',
    LANGUAGE: 'en_US',
    MAXMOBNUM: 10,
    MINMOBNUM: 8,
    COUPON_BASE_URL: 'https://gft.ph/',
    bugsnag_version: '22092022'
  
  
    //   Brand Name - ABBOTTPHAARDEMO
    // Org ID - 2038
    // Cluster - APAC
    // Till ID - abbottphdemo
  };
  
  
  