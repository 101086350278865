<section class="select-brand-container">
  <div class="">
    <div class="text-center">
      <img src="../../assets/images/ABBOTT LOGO-01.svg" class="w-125 mt-5" />
      <img
        src="../../assets/images/Group 5170 (3).svg"
        class="w-75"
        alt="Rewards"
      />
      <p class="mb-3 px-4">
        <strong class="blue"
          >Welcome to Abbott Alaga Rewards!<br />
          You got <span class="light-blue">50 points</span> for signing
          up!</strong
        >
      </p>
      <p class="p-0">
        To earn your points from your
        <span *ngIf="category == 'glucerna'" class="blue"
          >Glucerna<sup>®</sup> <br />
        </span>

        <span *ngIf="category == 'ensure'" class="blue"
          >Ensure Gold<sup>®</sup> <br />
        </span>
        <span *ngIf="category == 'pediasure'" class="blue"
          >Pediasure Plus<sup>®</sup> <br />
        </span>
        <span *ngIf="category == 'similac'" class="blue"
        >Similac<sup>®</sup>GainSchool<sup>®</sup> <br />
      </span>
        purchase, click
        <span class="blue">Next</span>
      </p>

      <button
        type="button"
        class="primaryBtn btn-white mt-4"
        [routerLink]="['/disclaimer']"
      >
        Next
      </button>
    </div>
    <p class="text-center bottom">Per DOH-FDA CFRR Promo Permit 0009 s. 2023</p>
    <div class="bar bt"></div>
  </div>
</section>
