import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "src/app/service/api.service";
import { ModalDirective } from "ngx-bootstrap/modal";
import { BugsnagService } from "src/app/services/bugsnag.service";
import { first } from "rxjs/operators";
import * as moment from "moment";
@Component({
  selector: 'app-similac-dashboard',
  templateUrl: './similac-dashboard.component.html',
  styleUrls: ['./similac-dashboard.component.scss']
})
export class SimilacDashboardComponent implements OnInit {

  customerData: any;
  customerName: string;
  loyaltyPoints: any;
  customerNamePresent = false;
  @ViewChild("hambergermenu", { static: true }) hambergermenu: ModalDirective;
  @ViewChild("glucernarewards", { static: true })
  glucernarewards: ModalDirective;
  @ViewChild("pendingTransPopup", { static: true }) pendingTransPopup: ModalDirective;

  productName: any;
  currentBrand: string;
  showGluPopupSite: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private bsService: BugsnagService
  ) { }

  ngOnInit(): void {
    this.getCustomer();
    this.productName = this.route.snapshot.params.name;
    this.currentBrand = 'similac'
    sessionStorage.setItem("currentBrand", "similac");
    this.getAllPoints();
  }

  isEmpty(value: any) {
    if (value == undefined || null || "") return "true";
    if (value == "true") return "true";
    if (value == "false") return "false";
    return value;
  }

  onClickBrand(brandName) {
    this.currentBrand = brandName;
    sessionStorage.setItem("currentBrand", this.currentBrand);
    if (this.currentBrand == 'ensure') {
      this.router.navigate(['/dashboard'])
    }else if (this.currentBrand == 'glucerna') {
      this.closeHamberger()
      this.router.navigate(['/glucerna/dashboard'])
    } else if (this.currentBrand == 'ensure') {
      this.closeHamberger()
    } else if (this.currentBrand == "pediasure") {
      this.router.navigate(["pediasure/dashboard", { brand: "pediasure" }]);
    } else if (this.currentBrand == "similac") {
      this.closeHamberger()
    }
  }

  getCustomer() {
    let mobile = sessionStorage.getItem("mobileNo");
    let token = sessionStorage.getItem("authToken");
    let key = sessionStorage.getItem("authKey");
    if (!mobile || !token) {
      console.log("customer not loggged in");
    } else {
      this.spinner.show();
      this.apiService.getCapCustomer(mobile, token).subscribe((res) => {
        if (res["status"].code == 200) {
          this.spinner.hide();
          this.customerData = res["customers"]["customer"][0];
          console.log(this.customerData);
          this.customerName =
            this.customerData.firstname + " " + this.customerData.lastname;
          this.customerNamePresent = true;
          this.loyaltyPoints = this.customerData.loyalty_points;
          // Block the user
          let block = res["customers"]["customer"][0].custom_fields.field;
          let blockUser = block.filter((filt) => {
            return filt.name == "block_count";
          });
          console.log(blockUser, "blockUser===");
          if (blockUser[0]?.value == "1") {
            this.spinner.hide();
            sessionStorage.clear();
            this.router.navigate(["/block-notice"]);
          }
          console.log(this.loyaltyPoints);
        } else {
          this.spinner.hide();
          this.customerNamePresent = false;
        }
      }),
        (err) => {
          this.spinner.hide();
          console.log(err);
          this.bsService.notifyError(err);
        };
    }
  }

  pointsList:any;
  productList:any = [];
  filteredProductList:any = [];
  similacProductList:any = [];
  getAllPoints() {
    let mobile = sessionStorage.getItem("mobileNo");
    let token = sessionStorage.getItem("authToken");
    //this.spinner.show();
    this.apiService
      .getProductsList(mobile)
      .pipe(first())
      .subscribe((res) => {
        this.spinner.hide();
        this.pointsList = res["result"];        
        this.pointsList.forEach((product: any) => {
          let productItem = {
            id: product?.id,
            name: product.product_name,
            image: product.product_image,
            status: product.receipt_status,
            productCode: product.id,
            submissionDate: moment(product.submission_date).format(),
            points: product.points,
            productStatus: product.status,
            color: "",
            30: "",
            90: "",
            yearAgo: "",
          };

          if (productItem.status == "approved") {
            productItem.status = "SUCCESS";
            productItem.color = "#30C878";
            if (productItem.points == null) {
              productItem.points = "";
            } else {
              productItem.points = "+" + productItem.points + " " + "pts";
            }
          } else if (productItem.status == "pending") {
            productItem.status = "PENDING RECEIPT REQUEST";
            productItem.points = "";
            productItem.color = "#8b8f91";
          } else if (productItem.status == "rejected") {
            productItem.status = "INVALID";
            productItem.points = "";

            productItem.color = "#fe2121";
          } else if (productItem.status == "awaiting") {
            productItem.status = "PENDING POINTS CONFIRMATION";
            productItem.color = "#EFBE35";
            productItem.points = "";
          }

          this.productList.push(productItem);
        });
        this.productList = this.productList.filter(
          (v, i, a) => a.findIndex((t) => t.productCode === v.productCode) === i
        );

        this.filteredProductList = this.productList.reverse();
        console.log("filtered: 269", this.filteredProductList)
        this.filteredProductList.forEach((product, i) => {
          let name = (product.name).toLowerCase()
          if (name.includes('similac')) this.similacProductList.push(product)
        })
        this.filteredProductList = this.similacProductList;
        let pendingstatus = []
        this.filteredProductList.forEach((product, i) => {
          let status = (product.status).toLowerCase()
          if (status.includes('pending')) pendingstatus.push(product)
        });
        this.filteredProductList = pendingstatus;
        console.log(this.filteredProductList?.length, 'final')
        if(this.filteredProductList?.length > 0){
          this.pendingTransPopup.show();
        }
      }),
      (err) => {
        console.log(err);
        this.spinner.hide();
        this.bsService.notifyError(err);
      };
  }
  checkValue(event) {
    var inputs = document.querySelectorAll(".check3");
    console.log("event: ", event.target.value);
    console.log("input: ", inputs[0]["checked"]);
    if (inputs[0]["checked"] == true)
      sessionStorage.setItem("showGluPopupSite", "false");
    else sessionStorage.setItem("showGluPopupSite", "true");
  }
  onClickGotIt() {
    this.router.navigate(["/glucerna/dashboard"]);
    this.glucernarewards.hide();
    this.closeHamberger();
  }

  onClickProductBenefits() {
    this.router.navigate(["product-benefits", { name: "ensure" }]);
  }

  onClickAboutSimilac() {
    this.router.navigate(["similac/about", { name: "similac" }]);
  }

  onClickAccoutLogin() {
    this.router.navigate(["login-signup"]);
  }

  openHamberger() {
    this.hambergermenu.show();
  }
  closeHamberger() {
    this.hambergermenu.hide();
  }

  onClickCancelpopup() {
    this.pendingTransPopup.hide();
  }

}
