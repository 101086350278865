import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { BugsnagService } from 'src/app/services/bugsnag.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
  iSprintData: any;
  latitude: string;
  longitude: string;
  confidence: string;
  scanCode: string;
  skuPoint: any;
  category: any;
  constructor(private router: Router, private apiService: ApiService, private route: ActivatedRoute, private bsService: BugsnagService) { }

  ngOnInit(): void {
    this.iSprintData = sessionStorage.getItem('iSprintData');
    this.iSprintData = JSON.parse(this.iSprintData);
    console.log(this.iSprintData, 'this.iSprintData ')
    this.scanCode = sessionStorage.getItem('scannedCode');
    this.category = this.iSprintData?.category_code?.toLowerCase()
    sessionStorage.setItem('categoryCode', this.category)
    if (this.category.startsWith('ensure')) this.category = "ensure"
    if (this.category.startsWith('pediasure')) this.category = "pediasure"
    if (this.category.startsWith('glucerna')) this.category = "glucerna"
    if (this.category.startsWith('similac')) this.category = "similac"
    console.log(this.scanCode, 'this.scanCode')
    if(this.category == 'similac'){
    const sku = this.iSprintData.sku;
    let modifiedSku = '';
    for (let i = 0; i < sku.length; i++) {
      const char = sku[i];
      if (/[a-zA-Z0-9]/.test(char)) {
        modifiedSku += char;
        this.iSprintData.sku = modifiedSku
      }
    }
  }
    this.apiService.getSkuPoints(this.iSprintData.sku).subscribe(
      (res: any) => {

        this.skuPoint = res.data[0]?.points;
        console.log(this.skuPoint, 'skuPoint == ')
      }, err => {
        this.bsService.notifyError(err);
      });
  }

  onClickDashborad() {
    if (this.category.startsWith('ensure')) this.router.navigate(['dashboard'])
    else if (this.category.startsWith('glucerna')) this.router.navigate(['glucerna/dashboard', { name: 'glucerna' }])
    else if (this.category.startsWith('pediasure')) this.router.navigate(['pediasure/dashboard', { name: 'pediasure' }])
    else if (this.category.startsWith('similac')) this.router.navigate(['similac/dashboard', { name: 'similac' }])


  }

  onClickTransactionHistory() {
    if (this.category.startsWith('ensure')) this.router.navigate(['profile/transaction-history'])
    else if (this.category.startsWith('glucerna')) this.router.navigate(['glucerna/transactions-history'])
    else if (this.category.startsWith('pediasure')) this.router.navigate(['pediasure/transaction-history'])
    else if (this.category.startsWith('similac')) this.router.navigate(['similac/transaction-history'])

  }

}
