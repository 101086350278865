import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from '../service/api.service';
import { BugsnagService } from '../services/bugsnag.service';

@Component({
  selector: 'app-site-switcher',
  templateUrl: './site-switcher.component.html',
  styleUrls: ['./site-switcher.component.scss']
})
export class SiteSwitcherComponent implements OnInit {
  customerData: any;
  customerName: string;
  customerNamePresent: boolean;
  loyaltyPoints: any;
  @ViewChild("glucernarewards", { static: true }) glucernarewards: ModalDirective;
  showGluPopupSite: string = 'true';
  isMobileAvailable: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private bsService: BugsnagService
  ) { }

  ngOnInit(): void {
    this.getCustomer()
    this.isMobileAvailable = this.isEmptyMobile(sessionStorage.getItem('mobileNo'))
  }

  isEmptyMobile(mobile: any) {
    return mobile == undefined || mobile == '' || mobile == null ? false : true
  }

  isEmpty(value: any) {
    if (value == undefined || null || '') return 'true'
    if (value == 'true') return 'true'
    if (value == 'false') return 'false'
    return value;
  }

  onClickEnsure() {
    sessionStorage.setItem("currentBrand", 'ensure')
    this.router.navigate(['/dashboard', { name: 'ensure' }])
  }
  onClickSimilac() {
    // open popup here
    sessionStorage.setItem("currentBrand", 'similac')
    this.router.navigate(['similac/dashboard', { name: 'similac' }])
  }
  checkValue(event) {
    var inputs = document.querySelectorAll(".check3");
    if (inputs[0]['checked'] == true) sessionStorage.setItem('showGluPopupSite', 'false')
    else sessionStorage.setItem('showGluPopupSite', 'true')
  }

  getCustomer() {
    let mobile = sessionStorage.getItem("mobileNo");
    let token = sessionStorage.getItem("authToken");
    let key = sessionStorage.getItem("authKey");
    if (!mobile || !token) {
      console.log("customer not loggged in");
    } else {
      this.spinner.show();
      this.apiService.getCapCustomer(mobile, token).subscribe((res) => {
        if (res["status"].code == 200) {
          this.spinner.hide();
          this.customerData = res["customers"]["customer"][0];
          console.log(this.customerData);
          this.customerName =
            this.customerData.firstname + " " + this.customerData.lastname;
          this.customerNamePresent = true;
          console.log(this.customerNamePresent, "pres")
          this.loyaltyPoints = this.customerData.loyalty_points;
          // Block the user
          let block = res["customers"]["customer"][0].custom_fields.field;
          let blockUser = block.filter((filt) => {
            return filt.name == "block_count";
          });
          console.log(blockUser, "blockUser===");
          if (blockUser[0]?.value == "1") {
            this.spinner.hide();
            sessionStorage.clear();
            this.router.navigate(["/block-notice"]);
          }
          console.log(this.loyaltyPoints);
        } else {
          this.spinner.hide();
          this.customerNamePresent = false;
        }
      }),
        (err) => {
          this.spinner.hide();
          console.log(err);
          this.bsService.notifyError(err);
        };
    }
  }

  onClickGlucerna() {
    sessionStorage.setItem("currentBrand", 'glucerna')
    this.router.navigate(['/glucerna/dashboard', { name: 'glucerna' }])
  }

  onClickPediasure() {
    // open popup here
    sessionStorage.setItem("currentBrand", 'pediasure')
    this.router.navigate(['pediasure/dashboard', { name: 'pediasure' }])

  }


}
